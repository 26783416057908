<template>
  <div>
    <transition name="fade">
      <preLoading v-if="isLoading"></preLoading>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
    };
  },
  created() {},
};
</script>
